import Navbar from "./othercomponents/NavbarNormie";
import "./CSS/Blog.css";
import { NavLink } from "react-router-dom";

// images
import Blog1 from "./Blogs/images/Blog1/Blog1.png";
import Blog2 from "./Blogs/images/Blog2/Blog2.png";
import Blog3 from "./Blogs/images/Blog3/Blog3.png";

import Blog7 from "./Blogs/images/Blog7/blog7banner.png";
import Blog8 from "./Blogs/images/Blog8/blog8banner.png";
import Blog9 from "./Blogs/images/Blog9/blog9banner.png";

const Blog = () => {
    return (
        <>
            <div>
                <center>
                    <Navbar />
                </center>
            </div>

            <br />

            <div id="blogPart">

                <h1 className="ourSetOfServices" >Blogs</h1> <br /><br />

                {/* Blog1 */}
                <div className="blogDivSep">
                    <div className="blogSubDivSep">
                        <img src={Blog1} alt="blog1" />
                        <div className="blogSubDivAfterImg" >
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum accusamus hic doloremque! Excepturi minus odit magnam deserunt ipsum consequuntur.</p> */}
                            <center>
                                <NavLink to="/blogs/ultimate-guide-to-insurance-coverage">
                                    <button>Read full post</button>
                                </NavLink>
                            </center>
                        </div>
                    </div>

                    {/* Blog 2 */}
                    <div className="blogSubDivSep">
                        <img src={Blog2} alt="blog2" />
                        <div className="blogSubDivAfterImg" >
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum accusamus hic doloremque! Excepturi minus odit magnam deserunt ipsum consequuntur.</p> */}
                            <center>
                                <NavLink to="/blogs/loans-myth-debunked">
                                    <button>Read full post</button>
                                </NavLink>
                            </center>
                        </div>
                    </div>

                    {/* Blog 3 */}
                    <div className="blogSubDivSep">
                        <img src={Blog3} alt="blog2" />
                        <div className="blogSubDivAfterImg" >
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum accusamus hic doloremque! Excepturi minus odit magnam deserunt ipsum consequuntur.</p> */}
                            <center>
                                <NavLink to="/blogs/How-to-Improve-Your-Score-and-Access-Better-Loans">
                                    <button>Read full post</button>
                                </NavLink>
                            </center>
                        </div>
                    </div>

                    {/* Blog 4 */}

                    <div className="blogSubDivSep">
                        <img src={"https://i.ibb.co/jwy1P5N/4.png"} alt="blog4" />

                        <div className="blogSubDivAfterImg" >
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum accusamus hic doloremque! Excepturi minus odit magnam deserunt ipsum consequuntur.</p> */}
                            <center>
                                <NavLink to="/blogs/Understanding-the-Fine-Print">
                                    <button>Read full post</button>
                                </NavLink>
                            </center>
                        </div>
                    </div>

                    {/* Blog 5 */}

                    <div className="blogSubDivSep">
                        <img src={"https://i.ibb.co/DfVXzBB/5.png"} alt="blog5" />
                        <div className="blogSubDivAfterImg" >
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum accusamus hic doloremque! Excepturi minus odit magnam deserunt ipsum consequuntur.</p> */}
                            <center>
                                <NavLink to="/blogs/From-Idea-to-LLC">
                                    <button>Read full post</button>
                                </NavLink>
                            </center>
                        </div>
                    </div>

                    {/* Blog 6 */}
                    <div className="blogSubDivSep">
                        <img src={"https://i.ibb.co/GkF68rW/6.png"} alt="blog2" />
                        <div className="blogSubDivAfterImg" >
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum accusamus hic doloremque! Excepturi minus odit magnam deserunt ipsum consequuntur.</p> */}
                            <center>
                                <NavLink to="/blogs/Tips-for-Navigating-Student-Loans-and-Managing-Educational-Debt">
                                    <button>Read full post</button>
                                </NavLink>
                            </center>
                        </div>
                    </div>

                    {/* Blog 7 */}
                    <div className="blogSubDivSep">
                        <img src={Blog7} alt="blog2" />
                        <div className="blogSubDivAfterImg" >
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum accusamus hic doloremque! Excepturi minus odit magnam deserunt ipsum consequuntur.</p> */}
                            <center>
                                <a href="/blogs/The-Importance-of-Financial-Planning-for-Individuals">
                                    <button>Read full post</button>
                                </a>
                            </center>
                        </div>
                    </div>

                    {/* Blog 8 */}
                    <div className="blogSubDivSep">
                        <img src={Blog8} alt="blog2" />
                        <div className="blogSubDivAfterImg" >
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum accusamus hic doloremque! Excepturi minus odit magnam deserunt ipsum consequuntur.</p> */}
                            <center>
                                <a href="/blogs/Small-Business-Tax-Essentials">
                                    <button>Read full post</button>
                                </a>
                            </center>
                        </div>
                    </div>

                    {/* Blog 9 */}
                    <div className="blogSubDivSep">
                        <img src={Blog9} alt="blog2" />
                        <div className="blogSubDivAfterImg" >
                            {/* <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum accusamus hic doloremque! Excepturi minus odit magnam deserunt ipsum consequuntur.</p> */}
                            <center>
                                <a href="/blogs/Navigating-the-World-of-Credit-Scores-and-Credit-Reports">
                                    <button>Read full post</button>
                                </a>
                            </center>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )
}

export default Blog;