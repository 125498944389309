import { NavLink } from "react-router-dom";
import Logo from "../BHANDARE FINANCE.png";

const NavbarNormie = () => {
    return (
        <>
            <div>
                <div>
                    <div className="servNav">

                        <nav className={`navbar navbar-expand-lg bg-transparent`}>
                            <img src={Logo} alt="" className="servicesLogoNav" />
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">

                                <i className="fa fa-navicon navbar-toggler" style={{ color: "black", fontSize: "28px" }}></i>
                            </button>

                            <div className={`collapse navbar-collapse form-inline my-2 my-lg-0 bg-transparent border-0`} id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto text-whitexd">
                                    <li className="nav-item active">
                                        <a className={`nav-link fw-bolder m-2`} href="/">Home</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className={`nav-link  fw-bolder m-2`} href="/">About</a>
                                    </li>
                                    <li className="nav-item dropdown m-2">
                                        <a className={`nav-link dropdown-toggle  fw-bolder`} href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Loans
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/homeloan" >Home Loan</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold" to="/services/personalloan" >Personal Loan</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold" to="/services/businessloan" >Business Loan</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold" to="/services/carloan" >Car Loan</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold" to="/services/educationloan" >Education Loan</NavLink></li>

                                        </div>
                                    </li>
                                    <li className="nav-item dropdown m-2">
                                        <a className={`nav-link dropdown-toggle  fw-bolder`} href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Insurance
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/lifeinsurance" >Life Insurance</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/healthinsurance" >Health Insurance</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/vehicleinsurance" >Vehicle Insurance</NavLink></li>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown m-2">
                                        <a className={`nav-link dropdown-toggle  fw-bolder`} href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Business Registration
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/labourlicense" >Labour License</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/tradelicense" >Trade License</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/gst" >GST Registration</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/foodlicense" >Food License {"(FSSAI)"} </NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/trademarkreg" >Trademark Registration</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/msme" >MSME</NavLink></li>
                                        </div>
                                    </li>
                                    <li className="nav-item dropdown m-2">
                                        <a className={`nav-link dropdown-toggle  fw-bolder`} href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Filings
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/incometax" >Income Tax</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/accounting" >Accounting</NavLink></li>
                                            <li><NavLink style={{ margin: "2px auto" }} className="dropdown-item text-dark fw-bold bg-white" to="/services/gstfiling" >GST Filing</NavLink></li>
                                        </div>
                                    </li>
                                </ul>

                            </div>
                        </nav>
                    </div>

                </div>
            </div>
        </>
    )
}

export default NavbarNormie