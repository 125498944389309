import Navbar from "../othercomponents/NavbarNormie";
import "../CSS/Blog.css";
// import Banner from "./images/Blog1/Blog1.png";
import Footer from "../othercomponents/Footer";

const Blog = () => {
    return (
        <>
            <div>
                <center>
                    <Navbar />
                </center>
            </div>

            <div className="blogBannerBlog9"></div>

            <div className="blogtextMain">

                <div className="blogtext">

                    <h2>Navigating the World of Credit Scores and Credit Reports</h2> <br />

                    <p>Your credit score is more than just a number; it's a key that unlocks a world of financial opportunities. At Bhandare Finserve, we specialize in providing financial services that help individuals harness the power of their credit. In this blog, we'll dive deep into the world of credit scores and credit reports, demystifying the concepts and offering valuable insights to help you navigate this critical aspect of your financial life. Plus, we'll show you how our services can assist you in managing your credit and accessing affordable loans, insurances, and more from trusted banking partners.</p>

                    <h5><strong>Understanding Credit Scores</strong></h5>
                    <p>Your credit score is a three-digit number that reflects your creditworthiness. It's a crucial factor that lenders consider when you apply for loans, credit cards, or other financial products. Here's what you need to know: </p>

                    <br />

                    <ul>
                        <li><strong><u>Credit Scoring Models: </u></strong>Two common credit scoring models are FICO and VantageScore. Each uses a slightly different algorithm to calculate your score, but the factors they consider are similar.</li>
                        <li><strong><u>Key Factors: </u></strong>Your credit score is influenced by factors such as your payment history, credit utilization, length of credit history, types of credit, and recent credit inquiries.</li>
                        <li><strong><u>Range: </u></strong>Credit scores typically range from 300 to 850, with higher scores indicating better creditworthiness. A score above 700 is generally considered good. </li>
                    </ul>

                    <br />

                    <h5><strong>Accessing Your Credit Report</strong></h5>

                    <p>Your credit report is a detailed record of your credit history. You're entitled to one free credit report from each of the three major credit bureaus—Equifax, Experian, and TransUnion—every 12 months. </p>
                    <strong>Here's how to access it: </strong>
                    <p><u><strong>AnnualCreditReport.com: </strong></u> Visit this website to request your free credit reports. Review them for accuracy, looking for errors or discrepancies that could affect your credit score.</p>
                    <p><u><strong>Regular Monitoring: </strong></u>Consider monitoring your credit throughout the year by rotating among the three credit bureaus. This can help you catch and address issues promptly. </p>

                    <br />

                    <h5><strong>Improving Your Credit Score </strong></h5>
                    <p>If your credit score is lower than you'd like, don't worry. You can take steps to improve it: </p>

                    <br />

                    <ol>
                        <li><strong><u>Pay Bills on Time: </u></strong>Consistently making on-time payments is the most critical factor in boosting your credit score.</li>
                        <li><strong><u>Low credit balance: </u></strong>Aim to keep your credit card balances below 30% of your credit limit. High credit utilization can negatively impact your score.</li>
                        <li><strong><u>Diversify Credit Types: </u></strong> Having a mix of credit types, such as credit cards, loans, and mortgages, can positively impact your score. </li>
                        <li><strong><u>Avoid Opening Too Many New Accounts: </u></strong>Each new credit inquiry can temporarily lower your score, so be cautious when applying for new credit.</li>
                        <li><strong><u>Keep Old Accounts Open: </u></strong> The length of your credit history matters. Keep older accounts open, even if you're not actively using them.</li>
                    </ol>

                </div>

            </div>

            <Footer />

        </>
    )
}

export default Blog;